// Sandbox
$(function () {
	$.nette.init();

	var selectpickerInit = (function selectpickerFunc () {
		$('.ajaxselectpicker')
			.selectpicker({
				liveSearch: true,
			})
			.ajaxSelectPicker({
				preserveSelected: true,
				minLength: 3,
				langCode: $('html').attr('lang'),
			});

		return selectpickerFunc;
	})();



	$('body').on('click', '.modal-ajax', function () {
		var href = $(this).attr('href');
		var target = $(this).data('target');

		$.nette.ajax({
			url: href,
			success: function () {
				$(target).modal('show');
			},
		});


		return false;
	});

	// Filtr na strance TRANSAKCE -----------------------------------------------------------------------------
	$.nette.ext('snippets').after(function ($el) {
		selectpickerInit();
		if ($el[0].id === 'snippet--statFilter') {

			// Filtr na strance Statistiky REGION
			var regions = document.querySelector('.form-group.expandable.regions');

			// Vytvareni <div> (rodice) pro stitky vybranych regionu
			var regionsActiveChoice = document.createElement('div');
			regionsActiveChoice.classList.add('active-choice');
			regions.append(regionsActiveChoice);

			// Tlacitko Vyberte region
			// var regionsForm = document.querySelector('.form-group.expandable.regions .form-check');
			// var btnShow = document.createElement('div');
			// btnShow.classList.add('choice-region');
			// btnShow.innerHTML = 'Vyberte region:';
			// regionsForm.prepend(btnShow);

			// Pridani vsech stitku regionu
			var regionsFormCheckboxes = document.querySelectorAll('.form-group.expandable.regions .form-check' +
				' .container-checkbox');
			var regionsFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.regions .form-check' +
				' .container-checkbox input');

			regionsFormCheckboxesInput.forEach((element, index) => {
				// Vytvareni vsech stitku regionu
				var activeLabel = document.createElement('div');
				activeLabel.classList.add(`region-${ index }`);
				activeLabel.innerHTML = regionsFormCheckboxes[index].textContent;
				regionsActiveChoice.append(activeLabel);

				// Zobrazovani ybranych stitku regionu
				if (element.checked) {
					var activeLabelChecked = document.querySelector(`.region-${ index }`);
					activeLabelChecked.classList.add('show');
				}
			});

			// Filtr na strance Statistiky MANAGER
			var regionManagers = document.querySelector('.form-group.expandable.region-managers');

			// Vytvareni <div> (rodice) pro stitky vybranych manageru regionu
			var regionManagersActiveChoice = document.createElement('div');
			regionManagersActiveChoice.classList.add('active-choice');
			regionManagers.append(regionManagersActiveChoice);

			// Tlacitko Vyberte manazera regionu
			// var regionsForm = document.querySelector('.form-group.expandable.regions .form-check');
			// var btnShow = document.createElement('div');
			// btnShow.classList.add('choice-region');
			// btnShow.innerHTML = 'Vyberte region:';
			// regionsForm.prepend(btnShow);

			// Pridani vsech stitku manazeru regionu
			var regionManagersFormCheckboxes = document.querySelectorAll('.form-group.expandable.region-managers' +
				' .form-check' +
				' .container-checkbox');
			var regionManagersFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.region-managers' +
				' .form-check' +
				' .container-checkbox input');

			regionManagersFormCheckboxesInput.forEach((element, index) => {
				// Vytvareni vsech stitku manazeru regionu
				var activeLabel = document.createElement('div');
				activeLabel.classList.add(`region-managers-${ index }`);
				activeLabel.innerHTML = regionManagersFormCheckboxes[index].textContent;
				regionManagersActiveChoice.append(activeLabel);

				// Zobrazovani ybranych stitku manazeru regionu
				if (element.checked) {
					var activeLabelChecked = document.querySelector(`.region-managers-${ index }`);
					activeLabelChecked.classList.add('show');
				}
			});

			// Filtr na strance Statistiky POS
			var admins = document.querySelector('.form-group.expandable.admins');

			// Tlacitko Vyberte pos
			// var adminsForm = document.querySelector('.form-group.expandable.admins .form-check');
			// var btnShowAdmins = document.createElement('div');
			// btnShowAdmins.classList.add('choice-admins');
			// btnShowAdmins.innerHTML = 'Vyberte pos:';
			// adminsForm.prepend(btnShowAdmins);

			// Vytvareni <div> (rodice) pro stitky vybranych posu
			var adminsActiveChoice = document.createElement('div');
			adminsActiveChoice.classList.add('active-choice');
			admins.append(adminsActiveChoice);

			// Pridani vsech stitku posu
			var adminsFormCheckboxes = document.querySelectorAll('.form-group.expandable.admins .form-check' +
				' .container-checkbox');
			var adminsFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.admins .form-check' +
				' .container-checkbox input');

			adminsFormCheckboxesInput.forEach((element, index) => {
				// Vytvareni vsech stitku posu
				var activeLabel = document.createElement('div');
				activeLabel.classList.add(`admin-${ index }`);
				activeLabel.innerHTML = adminsFormCheckboxes[index].textContent;
				adminsActiveChoice.append(activeLabel);

				// Zobrazovani ybranych stitku posu
				if (element.checked) {
					var activeLabelChecked = document.querySelector(`.admin-${ index }`);
					activeLabelChecked.classList.add('show');
				}
			});

		}
	});

	if (typeof $.fn.datepicker !== 'undefined') {
		$.fn.datepicker.defaults.language = $('html').attr('lang');
	}
});

// Filtr na strance Statistiky REGION a POS ---------------------------------------------
document.addEventListener('change', (element) => {
	// Region
	if (element.target.classList.contains('regions-input') && element.target.value !== '') {
		var regionsFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.regions .form-check' +
			' .container-checkbox input');
		regionsFormCheckboxesInput[0].checked = false;
		$('#frm-statFilter-statFilter').submit();
	}
	else if (element.target.classList.contains('regions-input') && element.target.value == '') {
		var regionsFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.regions .form-check' +
			' .container-checkbox input');
		regionsFormCheckboxesInput.forEach((element) => {
			if (element.checked && element.value !== '') {
				element.checked = false;
			}
		});
		$('#frm-statFilter-statFilter').submit();
	}

	// Managers region
	if (element.target.classList.contains('regional-manager-input') && element.target.value !== '') {
		var regionManagersFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.region-managers .form-check' +
			' .container-checkbox input');
		regionManagersFormCheckboxesInput[0].checked = false;
		$('#frm-statFilter-statFilter').submit();
	}
	else if (element.target.classList.contains('regional-manager-input') && element.target.value == '') {
		var regionManagersFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.region-managers .form-check' +
			' .container-checkbox input');
		regionManagersFormCheckboxesInput.forEach((element) => {
			if (element.checked && element.value !== '') {
				element.checked = false;
			}
		});
		$('#frm-statFilter-statFilter').submit();
	}

	// POS
	// Kliknuti na jakyjkoliv checkbox krome Vsechny
	if (element.target.classList.contains('admins-input') && element.target.value !== '') {
		var adminsFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.admins .form-check' +
			' .container-checkbox input');
		adminsFormCheckboxesInput.forEach((element, index) => {
			// Zobrazovani ybranych stitku posu
			if (element.checked) {
				var activeLabelChecked = document.querySelector(`.admin-${ index }`);
				activeLabelChecked.classList.add('show');
			}
			else if (!element.checked) {
				// Smazat stitek kdyz odskrknout checkbox
				document.querySelector(`.admin-${ index }`).classList.remove('show');
			}
		});
		adminsFormCheckboxesInput[0].checked = false;

		document.querySelector('.admin-0').classList.remove('show');
	}
	// Kliknuti na checkbox Vsechny
	else if (element.target.classList.contains('admins-input') && element.target.value == '') {
		var regionsFormCheckboxesInput = document.querySelectorAll('.form-group.expandable.admins .form-check' +
			' .container-checkbox input');
		regionsFormCheckboxesInput.forEach((element, index) => {
			// Odskrknuti vsech checkboxu a smazani jejich stitku krome Vsechny
			if (element.checked && element.value !== '') {
				element.checked = false;
				document.querySelector(`.admin-${ index }`).classList.remove('show');

				document.querySelector('.admin-0').classList.add('show');
			}
			else if (element.checked && element.value == '') {
				document.querySelector('.admin-0').classList.add('show');
			}
			else if (!element.checked) {
				// Smazat stitek kdyz odskrknout checkbox
				document.querySelector(`.admin-${ index }`).classList.remove('show');
			}
		});
	}
});

// NodeList.forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

document.addEventListener('DOMContentLoaded', function () {
	// MAGIC: Touchstart listener zpusobi, ze v iOS se zacnou pouzivat CSS :active styly apod.
	window.addEventListener('touchstart', function () {
		return;
	});

	// Potvrzeni upravy clena (Stranka Uprava clena)

	var btnSaveChanges = document.querySelector('.btn-save-changes');
	var confirmSaveChanges = document.querySelectorAll('.confirm-save-changes');

	if (btnSaveChanges) {
		btnSaveChanges.addEventListener('click', function () {
			btnSaveChanges.style.display = 'none';
			for (var i = 0; i < confirmSaveChanges.length; i++) {
				confirmSaveChanges[i].style.display = 'inline-block';
			}
		});
	}

	// Vytvareni promo akce (Stranka Nova promo akce)

	var formCheckInputExtra = document.querySelectorAll('.promo-type-switch .form-check-input');
	var formGroupExtra = document.querySelectorAll('.form-group.extra');
	var formGroupReferral = document.querySelectorAll('.form-group.referral');

	var promoTypeListener = function () {
		if (this.value === 'extra') {
			formGroupExtra.forEach((element) => { element.style.display = 'block'; });
			formGroupReferral.forEach((element) => { element.style.display = 'none'; });
		}
		else if (this.value === 'referral') {
			formGroupExtra.forEach((element) => { element.style.display = 'none'; });
			formGroupReferral.forEach((element) => { element.style.display = 'block'; });
		}
	};

	for (var i = 0; i < formCheckInputExtra.length; i++) {
		formCheckInputExtra[i].addEventListener('change', promoTypeListener, false);
		formCheckInputExtra[i].addEventListener('click', promoTypeListener, false);
		if (formCheckInputExtra[i].checked) {
			formCheckInputExtra[i].click();
		}
	}

	// Pridani noveho clena
	var formCheckInput = document.querySelectorAll('.form-check-input[name="country"]');
	var userFormPhone = document.querySelector('#frm-addUserForm-userForm-phone');

	if (userFormPhone) {
		var dataPhoneCode = JSON.parse(formCheckInput[0].dataset.phoneCode);

		for (var j = 0; j < formCheckInput.length; j++) {
			formCheckInput[j].addEventListener('change', function () {
				if (userFormPhone.value.length < 5) {
					userFormPhone.value = dataPhoneCode[this.value];
				}
			});
		}

	}

	// Select wrapper arrow (<select disabled> = display:none)
	var selectWrapperAll = document.querySelectorAll('select');

	for (var s = 0; s < selectWrapperAll.length; s++) {
		if (selectWrapperAll[s].disabled) {
			selectWrapperAll[s].parentElement.classList.add('disabled');
		}
	}

});
